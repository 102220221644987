<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.document_status_paths') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('document_status_paths.name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('document_status_paths.name')"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('document_status_paths.module') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.module_id" class="custom-select" @change="setModuleSlug" :class="validation && validation.module_id ? 'is-invalid' : ''">
                      <option v-for="(row, index) in module_type_list" :value="row.id" :key="'module_type'+index">{{row.name}}</option>
                    </select>
                    <span v-if="validation && validation.module_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.module_id[0] }}
                            </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('document_status_paths.module_slug') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.module_slug" class="form-control" :class="validation && validation.module_slug ? 'is-invalid' : ''" :placeholder="$t('document_status_paths.module_slug')"/>
                    <span v-if="validation && validation.module_slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.module_slug[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('document_status_paths.document') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.doc_type" @change="setDocSlug" class="custom-select" :class="validation && validation.doc_type ? 'is-invalid' : ''">
                      <option v-for="(row, index) in doc_type_list" :value="row.id" :key="'doc_type'+index">{{row.document_name}}</option>
                    </select>
                    <span v-if="validation && validation.doc_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.doc_type[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('document_status_paths.document_slug') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.doc_slug" class="form-control" :class="validation && validation.doc_slug ? 'is-invalid' : ''" :placeholder="$t('document_status_paths.document_slug')"/>
                    <span v-if="validation && validation.doc_slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.doc_slug[0] }}
                            </span>
                  </div>



                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('document_status_paths.applied_roles') }}</label>
                    <multiselect
                        v-model="data.applied_roles"
                        :placeholder="$t('document_status_paths.applied_roles')"
                        label="name"
                        track-by="id"
                        :options="roles_list"
                        :multiple="true"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.applied_roles" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.applied_roles[0] }}
                            </span>
                  </div>

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('document_status_paths.removed_roles') }}</label>
                    <multiselect
                        v-model="data.removed_roles"
                        :placeholder="$t('document_status_paths.removed_roles')"
                        label="name"
                        track-by="id"
                        :options="roles_list"
                        :multiple="true"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.removed_roles" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.removed_roles[0] }}
                            </span>
                  </div>

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('document_status_paths.description') }}</label>
                    <textarea class="form-control" v-model="data.description"></textarea>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_main" name="check-button" switch>{{ $t('document_status_paths.is_main') }}</b-form-checkbox>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-lg-12 pt-8">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('document_status_paths.status_list') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
              </div>
              <div class="form-group">
                <div class="bg-white">
                  <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                    <thead>
                    <tr>
                      <th>{{ $t('document_status_paths.status') }}</th>
                      <th>{{ $t('document_status_paths.is_active') }}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(doc, index) in status_data" :key="index">
                      <td>
                        <select type="text" v-model="doc.status_id" @input="setStatusInRepeater($event, index)" class="custom-select">
                          <option v-for="(row, index) in status_data_list" :value="row.id" :key="'status_data'+index">{{row.name}}</option>
                        </select>
                      </td>
                      <td>
                        <b-form-checkbox size="lg" v-model="doc.is_active" name="check-button" switch></b-form-checkbox>
                      </td>
                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from",
  data() {
    return {
      mainRoute: 'settings/document_status_paths',
      mainRouteDependency: 'base/dependency',
      items: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        module_id: null,
        module_slug: null,
        doc_type: null,
        doc_slug: null,
        description: null,
        applied_roles: [],
        removed_roles: [],
        is_active: true,
        is_main: false,
      },
      isEditing: false,
      validation: null,
      repeater_status_data: {id: null, status_id: null, status_slug: null, is_active: false},
      status_data: [],

      module_type_list: [],
      doc_type_list: [],
      status_data_list: [],
      roles_list: [],

    };
  },
  watch:{
    'data.module_id': function (val){
      if (val){
        this.getDocType(val)
      }else {
        this.doc_type_list = [];
      }
    },
    'data.doc_slug': function (val){
      if (val){
        this.getStatusDataList(val)
      }else {
        this.status_data_list = [];
      }
    },
  },
  methods: {
    setModuleSlug(event){
      let _val = event.target.value;
      let mod = this.module_type_list.find((row) => row.id == _val);
      if (mod){
        this.data.module_slug = mod.slug;
      }
    },
    setDocSlug(event){
      let _val = event.target.value;
      let doc = this.doc_type_list.find((row) => row.id == _val);
      if (doc){
        this.data.doc_slug = doc.document_slug;
      }

    },
    setStatusInRepeater(event, index){
      let _val = event.target.value;
      let doc = this.status_data_list.find((row) => row.id == _val);
      if (doc){
        this.status_data[index].status_slug = doc.slug;
      }
    },
    save() {
      if (this.isEditing) {
        this.update();
      }
      else {
        this.create();
      }
    },

    create() {

      ApiService.post(this.mainRoute, {
        ...this.data,
        doc_type_path: this.status_data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/document-status-paths');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        doc_type_path: this.status_data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/document-status-paths');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.module_id = response.data.data.module_id;
        this.data.module_slug = response.data.data.module_slug;
        this.data.doc_type = response.data.data.doc_type;
        this.data.doc_slug = response.data.data.doc_slug;
        this.data.description = response.data.data.description;

        this.data.is_active = response.data.data.is_active;
        this.data.is_main = response.data.data.is_main;

        this.data.applied_roles = response.data.data.applied_roles ? response.data.data.applied_roles : [];
        this.data.removed_roles = response.data.data.removed_roles ? response.data.data.removed_roles : [];
        this.status_data = response.data.data.doc_type_path ? response.data.data.doc_type_path : [];
      });
    },

    addToRepeater() {
      this.status_data.unshift(this.repeater_status_data);
      this.repeater_status_data = {id: null, status_id: null, status_slug: null, is_active: false};
    },
    deleteFromRepeater(index) {
      if (this.status_data.length > 1)
        this.status_data.splice(index, 1);
    },

    getRoles() {
      ApiService.get(this.mainRouteDependency+"/roles").then((response) => {
        this.roles_list = response.data.data.map((row)=>{
          return {
            id: row.id,
            name: row.name,

          }
        });
      });
    },
    async getModuleType() {
      await ApiService.get(this.mainRouteDependency+"/modules").then((response) => {
        this.module_type_list = response.data.data
      });
    },
    getDocType(module_type) {
      if (module_type){
        let _slug = '';
        let _row = this.module_type_list.find(row => row.id == module_type);
        if (_row){
          _slug = _row.slug;
        }
        ApiService.get(this.mainRouteDependency+"/module_document?module_type="+_slug).then((response) => {
          this.doc_type_list = response.data.data
        });
      }

    },
    getStatusDataList(doc_slug) {
      if (doc_slug) {
        let _slug_for_status = '';
        let _row = this.doc_type_list.find(row => row.document_slug == doc_slug);
        if (_row){
          _slug_for_status = _row.slug_for_status;
        }
        ApiService.get(this.mainRouteDependency+"/document_status?doc_slug="+_slug_for_status).then((response) => {
          this.status_data_list = response.data.data
        });
      }

    },



  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.document_status_paths"), route: '/settings/document-status-paths'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getRoles();
    let promise = this.getModuleType();
    if (this.idEdit) {
      Promise.all([promise]).then(()=>{
        this.getData();
      })

    }
    // else {
    //   if (this.data_items.length <= 0) {
    //     this.addToRepeater();
    //   }
    // }

  },
};
</script>


